Vue.component('search', {

    props: [
        'action'
    ],

    data() {
        return {
            term: '',
            pageResultsEnabled: false,
            brandResultsEnabled: false,
            results: [],
            searching: false,
            defaultResults: {
                products: [],
                categories: [],
                pages: [],
                brands: [],
                doctor_surgeries: [],
                debounced_search: null,
            },
        }
    },


    created() {
        let vm = this;
        this.results = this.defaultResults;

        this.debounced_search = debouncer(function() {
            if (vm.term.length > 1) {
                vm.searching = true;
                vm.$http.get(vm.action + '?term=' + vm.term, {
                    before: function (request) {
                        // abort previous request, if exists
                        if (vm.previousRequest) {
                            vm.previousRequest.abort();
                        }
                        // set previous request on Vue instance
                        vm.previousRequest = request;
                    }
                }).then(function (response) {
                    if (response.data.status === 'ok') {
                        vm.results = response.data;
                        vm.searching = false;
                    }
                }, function (response) {
                    //Catch failed request
                });
            }
        }, 800);
    },

    mounted() {
        let vm = this;

        $(document).ready(() => {
            $(window).click(function () {
                vm.term = '';
            });

            $('.searchForm').click(function (event) {
                event.stopPropagation();
            });

            $('.searchForm').submit(function(e) {
                e.preventDefault();
            });
        });
    },

    methods: {
        search: function () {
            this.debounced_search();
        },

        clearSearch: function () {
            this.term = '';
        },

        closeMainMenu: function () {
            //
        },

        selectItem: function (selected_item, type) {
            selected_item.type = type;
            this.results = this.defaultResults;
            this.$dispatch('searchSelection', selected_item);
        },

        formatPrice: function(price) {
            const formattedPrice = parseFloat(price);

            if (isNaN(formattedPrice)) {
                return '0.00';
            }

            return formattedPrice.toFixed(2);
        }
    }

});



$(document).ready(function() {

    if ($('.js_toggleSearch').length) {
        $('.js_toggleSearch').on('click', function(e){
            e.preventDefault();
            $(this).toggleClass('active');
        });

        if($('#closeSearch').length) {
            $('#closeSearch').on('click', function(e){
                e.preventDefault();
                $('.js_toggleSearch').removeClass('active');
            });
        }

        $(window).click(function() {
            $('.js_toggleSearch').removeClass('active');
        });

        $('.searchListItem').click(function(event){
            event.stopPropagation();
        });
    }

});
