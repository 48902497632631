<template>

    <div class="miniBasket">
        
        <a
                :href="basketUrl"
                class="button actionButton basketButton"
                title="View basket"
                @click="openMiniBasket($event)"
        >
            <span class="visuallyHidden">View Basket</span>
            <i class="svgIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="26" viewBox="0 0 29 26" fill="none">
                    <path d="M12.9643 2.38739L8.96072 10.0039H20.3367L16.3331 2.38739C16.0402 1.8015 16.2843 1.06914 16.8214 0.776199C17.4073 0.483255 18.1396 0.727375 18.4326 1.26444L22.9732 10.0039H27.1721C28.0021 10.0039 28.7344 10.7363 28.7344 11.5663C28.7344 12.4451 28.0021 13.1287 27.1721 13.1287L24.6332 23.2841C24.2915 24.6511 23.022 25.6276 21.6061 25.6276H7.74012C6.2754 25.6276 5.0548 24.6511 4.66421 23.2841L2.17418 13.1287C1.29535 13.1287 0.611816 12.4451 0.611816 11.5663C0.611816 10.7363 1.29535 10.0039 2.17418 10.0039H6.32423L10.8649 1.26444C11.1578 0.727375 11.8902 0.483255 12.476 0.776199C13.0131 1.06914 13.2572 1.8015 12.9643 2.38739ZM9.98603 15.4722C9.98603 15.0816 9.59543 14.691 9.20484 14.691C8.76543 14.691 8.42366 15.0816 8.42366 15.4722V20.1593C8.42366 20.5987 8.76543 20.9405 9.20484 20.9405C9.59543 20.9405 9.98603 20.5987 9.98603 20.1593V15.4722ZM14.6731 14.691C14.2337 14.691 13.8919 15.0816 13.8919 15.4722V20.1593C13.8919 20.5987 14.2337 20.9405 14.6731 20.9405C15.0637 20.9405 15.4543 20.5987 15.4543 20.1593V15.4722C15.4543 15.0816 15.0637 14.691 14.6731 14.691ZM20.9226 15.4722C20.9226 15.0816 20.532 14.691 20.1414 14.691C19.702 14.691 19.3602 15.0816 19.3602 15.4722V20.1593C19.3602 20.5987 19.702 20.9405 20.1414 20.9405C20.532 20.9405 20.9226 20.5987 20.9226 20.1593V15.4722Z" fill="currentColor"/>
                </svg>
            </i>
            <span v-html="itemCount" class="totalQty" v-if="itemCount > 0" v-cloak></span>
        </a>
        

        <div class="miniBasketFlyout" :class="[open ? 'isOpen' : '']" v-if="minibasketEnabled">
            
            <div class="innerMinibasket">
               
                <header class="minibasketHeader">
                    
                    <h2>Basket</h2>
                    
                    <button 
                            type="button" 
                            class="button closeButton" 
                            aria-label="Close mini basket"
                            @click.prevent="closeMiniBasket()"
                    >
                        <i class="svgIcon">
                            <font-awesome-icon :icon="['far', 'circle-xmark']" />
                        </i>
                    </button>
                    
                </header>

                <div class="minibasketContent">
                    
                    
                    <ul class="miniBasketItems" v-if="basket.items.length">

                        <div class="miniBasketErrors" v-if="limitErrors.length">

                            <div class="error" v-if="limitErrors.length">

                                You have exceeded the maximum allowed quantity on some of your items.

                            </div> <!-- .error -->

                        </div> <!-- .miniBasketErrors -->
                        
                        <template v-for="(product, productKey) in basket.items">
                            
                            <li :key="product.id">

                                <div class="basketCard">

                                    <div class="cardImage">

                                        <a :href="product.item_url" class="imageWrap" :title="'View ' + product.item_name">

                                            <img
                                                    :src="product.item_image"
                                                    width="100"
                                                    height="100"
                                                    :alt="'Image of:' + product.item_name"
                                                    v-if="product.item_image.length"
                                            >
                                            
                                            <img
                                                    src="/sites/default/images/placeholders/no-image-items-thumb.png"
                                                    width="100"
                                                    height="100"
                                                    alt="No image"
                                                    v-else
                                            >
                                            
                                        </a> <!-- .imageWrap -->

                                    </div> <!-- .cardLeft -->

                                    <div class="cardContent">

                                        <h4
                                                class="productTitle"
                                                v-text="product.item_name"
                                                v-if="product.item_name"
                                        ></h4>

                                        <div class="productSize" v-if="product.size">
                                            <span class="sizeValue" v-html="product.size"></span>
                                        </div> <!-- .quantityWrap -->
                                        
                                        <div class="quantityWrap" :class="[limitErrors.indexOf(product.id) > -1 ? 'limitError' : '']">
                                            <span class="productQtyLabel">Quantity: </span>
                                            <span class="productQty" v-html="product.quantity">1</span>
                                        </div> <!-- .quantityWrap -->
                                        
                                        
                                    </div> <!-- .cardRight -->

                                    <div class="cardRight">

                                        <button 
                                                type="button" 
                                                class="button deleteButton" 
                                                :aria-label="'Remove' + product.item_name + ' from basket'"
                                                @click.prevent="removeItem(productKey)"
                                        >
                                            <i class="svgIcon">
                                                <font-awesome-icon :icon="['far', 'trash']" />
                                            </i>
                                        </button>
                                        
                                        <span 
                                                class="productPrice"
                                                v-html="formatPrice(product.item_price, product.sale_price)"
                                        ></span>
                                    </div> <!-- .cardRight -->
                                    
                                    
                                </div> <!-- .basketCard -->

                                <div class="cardError" v-if="limitErrors.indexOf(product.id) > -1">
                                    You have exceeded the maximum allowed quantity on this item.
                                </div> <!-- .error -->
                                
                                
                            </li>
                            
                        </template>
                        
                    </ul>

                    <div class="noItemsBasket" v-else>
                        <h5>Your basket is currently empty</h5>
                    </div> <!-- .noItemsBasket -->
                    
                </div> <!-- .minibasketContent -->

                <footer class="minibasketFooter" v-if="basket.items.length">

                    <div class="basketFooterTop">
                        <span class="footerLabel">Total:</span>
                        <span 
                                class="footerTotal" 
                                v-html="formatPrice(basket.cost_subtotal)"
                        ></span>
                    </div> <!-- .basketFooterTop -->

                    <div class="basketFooterBottom">
                        
                        <a 
                                :href="basketUrl" 
                                title="Continue to checkout" 
                                class="buttonAlt"
                                :class="[limitErrors.length ? 'buttonDisabled' : '']"
                        >
                            Checkout
                        </a>
                        
                    </div> <!-- .basketFooterBottom -->
                    
                </footer>
                
                
            </div> <!-- .innerMinibasket -->

            <div class="loadingBox" :class="[loading ? 'isLoading' : '']">
                <loading-spinner
                        :loading="loading"
                        loading-text="Updating Basket"
                        :show-loading-text="true"
                ></loading-spinner>
            </div> <!-- .loadingBox -->
            
            
        </div> <!-- .miniBasketFlyout -->
        
    </div> <!-- .miniBasket -->

</template>

<script>
import _ from 'lodash';
import axios from 'axios';

export default {
    
    name: 'mtcMinibasket',
    
    props: {
        basketUrl: {
            type: [String],
            default: () => {
                return ''
            }
        },
        basketAjaxUrl: {
            type: [String],
            default: () => {
                return ''
            }
        },
        setCurrency: {
            type: [String],
            default: () => {
                return 'GBP'
            }
        },
        minibasketEnabled: {
            type: [Boolean],
            default: () => {
                return false
            }
        }
    },
    
    data() {
        return {
            basket: {
                cost_subtotal: 0,
                items: []
            },
            currency: false,
            buttonData: {
                itemCount: 88
            },
            loading: false,
            open: false
        }
    },
    
    computed: {
        itemCount() {
            
            if(!_.isUndefined(this.basket.items)) {
                return this.basket.items.length;
            }else{
                return 0;
            }
            
        },
        limitErrors() {
            
            if(typeof this.basket.limit_error != 'undefined') {

                const itemErrorIDs = Object.keys(this.basket.limit_error).map(id => {
                    return parseInt(id);
                });
                
                console.log(itemErrorIDs);
                return itemErrorIDs;
                
            }
            
            return [];
            
        }
    },
    
    mounted() {
        
        const vm = this;
        
        EventBus.$on('receiveBasketUpdate', basket => {
            vm.refreshBasket(true);
        });
        
    },
    
    created() {
        this.refreshBasket();
    },
    
    methods: {
        setCurrencyObject() {
            
            if(_.isUndefined(this.currencies[this.setCurrency])) {
                this.currency = false;
                return false;
            }
            
            let currency = this.currencies[this.setCurrency];
            
            for(const key in currency) {

                switch(key) {
                    case 'enabled':
                        currency[key] = currency[key] == '1'
                        break;
                    case 'ratio':
                        currency[key] = parseFloat(currency[key])
                        break;
                }
                
            }
            
            if(currency.enabled) {
                this.currency = currency;
                return true;
            }else{
                return false;
            }
        },

        formatPrice(price, salePrice) {

            if(_.isUndefined(salePrice)) {
                salePrice = 0;
            }else{
                salePrice = parseFloat(salePrice);
            }
            
            let floatPrice = parseFloat(price);
            
            if(isNaN(floatPrice)) {
                floatPrice = 0.0;
            }
            
            if((salePrice < floatPrice) && (salePrice > 0)) {
                floatPrice = salePrice;
            }
            
            let priceConverted = floatPrice * this.currency.ratio;
            return this.currency.symbol + priceConverted.toFixed(2);
            
        },
        
        removeItem(productKey) {
            
            const vm = this;
            vm.loading = true;
            
            vm.basket.items[productKey].quantity = 0;
            
            axios.post(vm.basketAjaxUrl, {
                ajax: true,
                action: 'update_qty',
                basket: vm.basket
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then( function (response) {
                if (response.data.status === 'ok') {
                    vm.basket = response.data.basket;
                }
                vm.loading = false;
            });
        },
        
        refreshBasket(open) {

            if(_.isUndefined(open)) {
                open = false;
            }
            
            const vm = this;
            vm.loading = true;
            
            axios.get('/shop/checkout/ajax.php?action=get_basket&ajax').then(function (response) {
                
                if (response.data.status === 'ok') {
                    vm.currencies = response.data.currencies;
                    vm.basket = response.data.basket;
                    vm.setCurrencyObject();
                }

                vm.loading = false;
                
                if(open) {
                    vm.openMiniBasket();
                }
            });
            
        },
        
        closeMiniBasket() {
            this.open = false;
        },

        openMiniBasket(event) {
   
            if(!_.isUndefined(event)) {
                event.preventDefault();
            }
            console.log(this.minibasketEnabled);
            if(this.minibasketEnabled) {
                
                const isBasketOrCheckout = document.getElementById('checkout');

                if (isBasketOrCheckout) {
                    this.open = false;
                    window.location = this.basketUrl;
                } else {
                    this.open = true;
                }
            }else{
                this.open = false;
                window.location = this.basketUrl;
            }
        }
    }
}
</script>

<style lang="less">
    
    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .miniBasket {
        
        position: relative;
        
        .miniBasketErrors {
            padding: 24px;
            
            .error {
                
                padding: 16px 24px !important;
                .rems(14) !important;
                line-height: 20px !important;
                
                &:last-child {
                    margin: 0;
                }
            }
        }
        
        .cardError {
            color: @alert_color;
            margin: -12px 0 12px;
            padding: 0 24px;
            .rems(12);
            line-height: 16px;
        }
        
        .miniBasketFlyout {
            
            position: fixed;
            top: 0;
            bottom: 0;
            height: 100vh;
            width: 50vw;
            max-width: 450px;
            background: rgba(255,255,255,1);
            backdrop-filter: blur(10px);
            filter: drop-shadow(4px 4px 16px rgba(0,0,0,0.24));
            z-index: 999999999;
            border-left: none;
            border-radius: 0;
            
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: transform 600ms, opacity 300ms, visibility 300ms;
            
          
            right: ~"calc(0px - ((100vw - @{header_width}) / 2))";
            transform: translateX(100%);
            
            @media all and (max-width: 1628px) {
                right: -24px;
                width: 100%;
                max-width: 450px;
            }
            
            @media @flyout {
                max-width: 360px;
            }

            @media @mobile {
                width: 100vw;
                max-width: 100vw;
                left: 0;
                right: 0;
                filter: none;
                border-radius: 0;
            }
            
            
            &.isOpen {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                transform: translateX(0);
                transition: transform 600ms, opacity 300ms, visibility 300ms;
                
            }
            
            .headerAssessment & {
                right: 0;
                
            }
            
        }
        
        .noItemsBasket {
            padding: 24px;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            h5 {
                text-align: center;
                margin: 0;
                padding: 0;
                .rems(20);
                line-height: 1.2em;
                color: @text_color;
            }
        }
        
        .innerMinibasket {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;
            z-index: 2;
        }
        
        .loadingBox {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            background: rgba(255,255,255,0.8);
            backdrop-filter: blur(10px);
            pointer-events: none;
            visibility: hidden;
            opacity: 0;
            transition: opacity 200ms linear, visibility 200ms linear;
            
            &.isLoading {
                visibility: visible;
                opacity: 1;
                transition: opacity 200ms linear, visibility 200ms linear;
            }
            
            .loadingSpinner {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);

                .loadingText {
                    margin-top: -24px;
                }
            }
            
            
        }
        
        .minibasketHeader {
            padding: 32px 56px 32px 24px;
            flex: 0 0 auto;
            position: relative;
            border-bottom: 1px solid rgba(0,0,0,0.08);
            
            & > h2 {
                .rems(24);
                line-height: 1.2em;
                color: @text_color;
                margin: 0;
                width: 100%;
                padding: 0;
            }
            
            .closeButton {
                border: none;
                background: transparent;
                color: @text_color;
                position: absolute;
                top: 50%;
                right: 10px;
                width: 48px;
                height: 48px;
                margin: 0;
                padding: 0;
                transition: color 200ms linear;
                cursor: pointer;
                transform: translateY(-50%);
                
                i {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin: 0;
                    
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
                
                &:hover, &:active, &:focus-visible {
                    color: @highlight_color;
                    transition: color 200ms linear;
                }
            }
        }
        
        .minibasketFooter {
            padding: 24px;
            flex: 0 0 auto;
            display: flex;
            border-top: 1px solid rgba(0,0,0,0.08);
            
            .basketFooterTop {
                .rems(18);
                line-height: 1.2em;
                color: @text_color;
                font-weight: bold;
                flex: 0 0 180px;
                display: flex;
                align-items: center;
            }
            
            .basketFooterBottom {
                flex: 1 1 100%;
                display: flex;
                align-items: center;
                
                .button, .buttonAlt {
                    
                    &.buttonDisabled {
                        opacity: 0.5 !important;
                        pointer-events: none !important;
                    }
                }
            }
            
            .footerLabel {
                display: inline-block;
                padding-right: 8px;
            }
            
            .footerTotal {
                display: inline-block;
            }
            
            .buttonAlt {
                margin: 0;
                width: 100%;
                padding: 16px;
            }
        }
        
        .minibasketContent {
            flex: 1 1 100%;
            width: 100%;
            overflow: hidden;
            
        }
        
        .miniBasketItems {
            float: none;
            clear: both;
            margin: 0;
            padding: 0 40px 0 0;
            background: transparent;
            overflow: auto;
            height: 100%;
            width: ~"calc(100% + 40px)";
          
            
            &:before, &:after {
                display: none;
            }
            
            li {
                border-bottom: 1px solid rgba(0,0,0,0.08);
                
                &:last-child {
                    border-bottom: 1px solid rgba(0,0,0,0);
                }
            }
            
        }
        
        .basketCard {
            
            display: flex;
            padding: 24px;
            
            .cardImage {
                flex: 0 0 80px;
            }
            
            .cardContent {
                flex: 1 1 auto;
                padding: 4px 16px;
                display: flex;
                flex-direction: column;
                .rems(12);
                line-height: 1.2em;
                font-weight: 600;
                
                .quantityWrap {
                    margin-top: auto;
                    display: flex;
                    padding-top: 8px;
                    
                    &.limitError {
                        color: @alert_color;
                    }
                }
                
                .productQtyLabel {
                    padding-right: 8px;
                }
                
                .productSize {
                    padding-top: 4px;
                }
               
            }
            
            .cardRight {
                flex: 0 0 88px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                
                .deleteButton {
                    
                    background: transparent;
                    border: none;
                    color: @text_color;
                    padding: 0;
                    width: 48px;
                    height: 48px;
                    position: relative;
                    top: -10px;
                    right: -5px;
                    cursor: pointer;
                    transition: color 200ms linear;
                    margin: 0;
                    
                    .svgIcon {
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    
                    &:hover, &:active, &:focus {
                        color: @alert_color;
                        transition: color 200ms linear;
                    }
                    
                }
                .productPrice {
                    margin-top: auto;
                    .rems(16);
                    line-height: 1.2em;
                    font-weight: bold;
                    padding-top: 8px;
                }
            }

            .imageWrap {

                display: block;
                width: 80px;
                height: 80px;
                margin: 0;
                padding: 0;
                position: relative;
                overflow: hidden;
                background: #fff;
                border-radius: 4px;
                border: 1px solid rgba(0,0,0,0.08);
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }

            }
            
            .productTitle {
                .rems(16);
                line-height: 1.2em;
                margin: 0;
                padding: 0;
            }
            
        }
    }


</style>
