var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gpSearchWrap" }, [
    _c(
      "div",
      {
        staticClass: "searchBoxWrap",
        class: [_vm.searchFocused ? "searchFocused" : ""],
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.term,
                expression: "term",
              },
            ],
            staticClass: "searchBox",
            attrs: {
              type: "search",
              name: "gp-search-term",
              placeholder: _vm.searchPlaceholder,
            },
            domProps: { value: _vm.term },
            on: {
              keyup: function ($event) {
                return _vm.search()
              },
              focus: function ($event) {
                _vm.searchFocused = true
              },
              blur: function ($event) {
                _vm.searchFocused = false
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.term = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.isSearching
            ? _c(
                "button",
                {
                  staticClass: "searchBoxIcon",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.clearSearch()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "xmark"] },
                  }),
                ],
                1
              )
            : _c(
                "button",
                {
                  staticClass: "searchBoxIcon",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.search()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "magnifying-glass"] },
                  }),
                ],
                1
              ),
        ]),
        _vm._v(" "),
        _vm.isSearching
          ? _c("div", { staticClass: "searchResultsBox" }, [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "loadingMessage" },
                    [
                      _c("loading-spinner", {
                        attrs: {
                          loading: _vm.loading,
                          "loading-text": "Loading GP search results",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "searchResultsWrap" }, [
                    _vm.hasSearchResults
                      ? _c(
                          "ul",
                          { staticClass: "searchResults" },
                          [
                            _vm._l(_vm.results, function (surgeryOption) {
                              return [
                                _c(
                                  "li",
                                  { key: "surgery" + surgeryOption.id },
                                  [
                                    _c("a", {
                                      attrs: {
                                        href: "#",
                                        title:
                                          "Click to select: " +
                                          _vm.surgeryAddress(surgeryOption),
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.surgeryAddress(surgeryOption)
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.selectSurgery(
                                            surgeryOption
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            }),
                          ],
                          2
                        )
                      : _c("div", { staticClass: "noResults" }, [
                          _c("p", [_vm._v("No surgeries found")]),
                        ]),
                  ]),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "editableOption" }, [
      _c("div", { staticClass: "row" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.editable,
              expression: "editable",
            },
          ],
          attrs: { type: "checkbox", id: "editable" + "-" + _vm.instanceId },
          domProps: {
            checked: Array.isArray(_vm.editable)
              ? _vm._i(_vm.editable, null) > -1
              : _vm.editable,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.editable,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.editable = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.editable = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.editable = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _c("label", {
          attrs: { for: "editable" + "-" + _vm.instanceId },
          domProps: {
            innerHTML: _vm._s(_vm.surgery.manual_doctor_surgery.label),
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    !_vm.surgeryIsEmpty() || _vm.editable
      ? _c("div", { staticClass: "surgerySelected" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editable,
                  expression: "!editable",
                },
              ],
              staticClass: "surgeryBox",
            },
            [
              _c(
                "button",
                {
                  staticClass: "closeButton",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.removeSurgery()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "visuallyHidden" }, [
                    _vm._v("Clear Address"),
                  ]),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "trash"] },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", [_vm._v("Selected surgery")]),
              _vm._v(" "),
              _vm._l(
                _vm.surgeryProps(_vm.surgery),
                function (fieldName, index) {
                  return [
                    _vm.isVisibleField(fieldName)
                      ? _c("div", { staticClass: "surgeryText" }, [
                          _c("span", {
                            staticClass: "textLabel",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.surgery[fieldName].label + ":"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _vm.surgery[fieldName]?.value?.length
                            ? _c("span", {
                                staticClass: "textValue",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.surgery[fieldName].value
                                  ),
                                },
                              })
                            : _c("span", { staticClass: "textValue" }, [
                                _vm._v("N/A"),
                              ]),
                        ])
                      : _vm._e(),
                  ]
                }
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editable,
                  expression: "editable",
                },
              ],
              staticClass: "surgeryBox surgeryAddressFields",
            },
            [
              _c("h4", [_vm._v("Enter your GP surgery address")]),
              _vm._v(" "),
              _vm._l(
                _vm.surgeryProps(_vm.surgery),
                function (fieldName, index) {
                  return [
                    _vm.isVisibleField(fieldName)
                      ? _c(
                          "div",
                          {
                            staticClass: "row",
                            class: [
                              !_vm.surgery[fieldName].isValid
                                ? "errorWrap"
                                : "",
                            ],
                          },
                          [
                            _c("div", { staticClass: "inputWrap" }, [
                              _c("label", {
                                attrs: {
                                  for:
                                    _vm.surgery.id.value +
                                    "_" +
                                    index +
                                    "_" +
                                    fieldName,
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.surgery[fieldName].label
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.surgery[fieldName].value,
                                    expression: "surgery[fieldName].value",
                                  },
                                ],
                                ref: "input_" + fieldName,
                                refInFor: true,
                                attrs: {
                                  type: "text",
                                  name:
                                    "doctor_surgery" +
                                    _vm.multiUserIDKeyString +
                                    "[" +
                                    fieldName +
                                    "]",
                                  id:
                                    _vm.surgery.id.value +
                                    "_" +
                                    index +
                                    "_" +
                                    fieldName,
                                },
                                domProps: {
                                  value: _vm.surgery[fieldName].value,
                                },
                                on: {
                                  keyup: function ($event) {
                                    _vm.surgery[fieldName].isValid = true
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.surgery[fieldName],
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _c("div", { staticClass: "hiddenFields" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.surgery[fieldName].value,
                                expression: "surgery[fieldName].value",
                              },
                            ],
                            attrs: {
                              type: "hidden",
                              name:
                                "doctor_surgery" +
                                _vm.multiUserIDKeyString +
                                "[" +
                                fieldName +
                                "]",
                              id:
                                _vm.surgery.id.value +
                                "_" +
                                index +
                                "_" +
                                fieldName,
                            },
                            domProps: { value: _vm.surgery[fieldName].value },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.surgery[fieldName],
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                  ]
                }
              ),
              _vm._v(" "),
              !_vm.fieldsAreValid
                ? _c(
                    "div",
                    { staticClass: "errorBox" },
                    [
                      _vm._l(
                        _vm.surgeryProps(_vm.surgery),
                        function (fieldName, index) {
                          return [
                            !_vm.surgery[fieldName].isValid
                              ? _c("div", { staticClass: "error" }, [
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.surgery[fieldName].validationMessage
                                      ),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "buttonActions" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: {
                      type: "button",
                      "aria-label": "Click here to set your address",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setEnteredAddress()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Update address\n                "
                    ),
                  ]
                ),
              ]),
            ],
            2
          ),
        ])
      : _c("div", { staticClass: "surgeryEmpty" }, [
          _c("h4", [
            _vm._v(
              "Please use the search above to find your GP's surgery or enter the address manually."
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }