var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mNumberInput" }, [
    _vm.inputType === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.counter,
              expression: "counter",
            },
          ],
          ref: "mNumberInput",
          class: ["mNumberInput__input", _vm.inputClass],
          attrs: {
            id: _vm.id,
            name: _vm.name,
            min: _vm.min,
            max: _vm.max,
            autocomplete: _vm.autocomplete,
            maxlength: _vm.maxlength,
            readonly: _vm.readonly,
            disabled: _vm.disabled,
            type: "checkbox",
          },
          domProps: {
            checked: Array.isArray(_vm.counter)
              ? _vm._i(_vm.counter, null) > -1
              : _vm.counter,
          },
          on: {
            input: _vm.updateValue,
            blur: function ($event) {
              $event.preventDefault()
              return _vm.checkNotEmpty.apply(null, arguments)
            },
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.incrementCounter.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.decrementCounter.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                )
                  return null
                $event.preventDefault()
              },
            ],
            change: function ($event) {
              var $$a = _vm.counter,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.counter = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.counter = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.counter = $$c
              }
            },
          },
        })
      : _vm.inputType === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.counter,
              expression: "counter",
            },
          ],
          ref: "mNumberInput",
          class: ["mNumberInput__input", _vm.inputClass],
          attrs: {
            id: _vm.id,
            name: _vm.name,
            min: _vm.min,
            max: _vm.max,
            autocomplete: _vm.autocomplete,
            maxlength: _vm.maxlength,
            readonly: _vm.readonly,
            disabled: _vm.disabled,
            type: "radio",
          },
          domProps: { checked: _vm._q(_vm.counter, null) },
          on: {
            input: _vm.updateValue,
            blur: function ($event) {
              $event.preventDefault()
              return _vm.checkNotEmpty.apply(null, arguments)
            },
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.incrementCounter.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.decrementCounter.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                )
                  return null
                $event.preventDefault()
              },
            ],
            change: function ($event) {
              _vm.counter = null
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.counter,
              expression: "counter",
            },
          ],
          ref: "mNumberInput",
          class: ["mNumberInput__input", _vm.inputClass],
          attrs: {
            id: _vm.id,
            name: _vm.name,
            min: _vm.min,
            max: _vm.max,
            autocomplete: _vm.autocomplete,
            maxlength: _vm.maxlength,
            readonly: _vm.readonly,
            disabled: _vm.disabled,
            type: _vm.inputType,
          },
          domProps: { value: _vm.counter },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.counter = $event.target.value
              },
              _vm.updateValue,
            ],
            blur: function ($event) {
              $event.preventDefault()
              return _vm.checkNotEmpty.apply(null, arguments)
            },
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.incrementCounter.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.decrementCounter.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                )
                  return null
                $event.preventDefault()
              },
            ],
          },
        }),
    _vm._v(" "),
    _c("div", { staticClass: "mNumberInput__buttons" }, [
      _c(
        "button",
        {
          staticClass: "mNumberInput__button mNumberInput__button--add",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.incrementCounter.apply(null, arguments)
            },
          },
        },
        [
          _vm._t("add", function () {
            return [_vm._v("+")]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "mNumberInput__button mNumberInput__button--subtract",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.decrementCounter.apply(null, arguments)
            },
          },
        },
        [
          _vm._t("subtract", function () {
            return [_vm._v("-")]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }