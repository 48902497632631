
$(document).ready(function () {
    "use strict";

    // if sidebar exists
    if ($('.js_contentSidebar').length) {
        showHideMenus('.js_contentSidebar .sidebarTitle:not(.alwaysShow)', window.breakpoints.flyout);

        $('.js_contentSidebar .innerSidebar').stick_in_parent({
            parent: '.contentSidebar',
            offset_top: 138
        });
    }

    // Accordion in menu
    $('.js_accordionMenu .hasChildren > a').on('click', function (e) {
        e.preventDefault();
        $(this).siblings('ul').stop(true, true).slideFadeToggle();
        $(this)
            .closest('.hasChildren').toggleClass('open')
            .siblings().removeClass('open')
            .children('ul:visible').slideFadeToggle();
    });



    $(window).on('resize', debouncer(function (e) {
        "use strict";

        // if sidebar exists
        if ($('.js_contentSidebar').length) {
            showHideMenus('.js_contentSidebar .sidebarTitle:not(.alwaysShow)', window.breakpoints.flyout);
        }

    })); // window resize

}); // document ready


