<template>

    <div class="productListings">

        
        
        <template v-if="selectionEnabled">

            <section class="listingsWrapper productsRecommendedWrapper"
                    v-if="hasRecommendedItems"
            >

                <div class="listingsTitle">
                    <h2>Recommended</h2>
                    <p>Based on our assessment, this is our recommendation</p>
                </div> <!-- .listingsTitle -->

                <ul class="productList productsRecommended">

                    <template v-for="product in products" v-if="product.recommended_from_answers">

                        <li :key="'product_id_' + product.id">

                            <product-card
                                    :product="product"
                                    :in-stock="product.in_stock"
                                    :from-price="product.from_price"
                                    :large="true"
                                    :basket-button-data="basketButtonData(product)"
                            ></product-card>
                        </li>

                    </template>

                </ul>

            </section>

            <section class="listingsWrapper productsSuitableWrapper"
                    v-if="hasOtherSuitableItems"
            >

                <div class="listingsTitle">
                    <h2>Suitable Treatments</h2>
                    <p>Based on our assessment, these are the suitable items we've found for you</p>
                </div> <!-- .listingsTitle -->
                
                <ul class="productList productsSuitable">

                    <template v-for="product in products" v-if="!product.recommended_from_answers">

                        <li :key="'product_id_' + product.id">

                            <product-card
                                    :product="product"
                                    :from-price="product.from_price"
                                    :in-stock="product.in_stock"
                                    :large="true"
                                    :basket-button-data="basketButtonData(product)"
                            ></product-card>
                        </li>

                    </template>

                </ul>


            </section>

            <section class="listingsWrapper"
                    v-if="!products?.length"
            >

                <div class="noTreatments">
                    <p>
                        Unfortunately we cannot offer you any treatments for this condition. <br>
                        Please feel free to <a href="/contact" title="Contact us for help">contact us</a> for more information.
                    </p>
                </div> <!-- .noTreatments -->

            </section>
            
           
        </template>
        
    </div> <!-- .productList -->
    
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import collapseArea from './collapse_area';

export default {
    name: "productListings",
    components: {
        collapseArea,
        Splide, 
        SplideSlide
    },
    props: {
        selectionEnabled: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        products: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        multiuserId: {
            type: [String, Boolean, Number],
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            sectionCollapsed: true,
            hasSelectionError: false,
            sliderOptions: {
                speed: 800,
                rewind: false,
                pagination: false,
                arrows: true,
                drag: true,
                perMove: 1,
                perPage: 2,
                gap: 55
            },
            productReady: false
        }
    },
    mounted() {
        
        this.$nextTick(() => {
            this.productReady = true;
        });
      
    },
    
    computed: {

        hasRecommendedItems() {
            for (const prop in this.products) {
                if (this.products[prop].recommended_from_answers) {
                    return true;
                }
            }
            return false;
        },
        hasOtherSuitableItems() {
            for (const prop in this.products) {
                if (!this.products[prop].recommended_from_answers) {
                    return true;
                }
            }
            return false;
        },
        recommendedTreatmentsTitle() {
            
            return 'Recommended' + this.multiUserPostfix();
            
        },
        suitableTreatmentsTitle() {
            return 'Suitable treatments' + this.multiUserPostfix();
        },
        selectionConfirmationTitle() {
            return 'Confirm your selection' + this.multiUserPostfix();
        },
        noProductsFoundTitle() {
            return 'No suitable treatments found' + this.multiUserPostfix();
        },
        noSuitableSelectedTitle() {
            return 'No suitable treatments selected' + this.multiUserPostfix();
        },
        productsAvailable() {
            return Object.keys(this.products).length;
        },
    },
    methods: {
        
        basketButtonData(product) {
            product.basket_button_data.add_direct_to_basket = true;
            product.basket_button_data.browse_url = product.basket_button_data.basket_url;
            product.basket_button_data.browse_label = product.basket_button_data.add_to_cart_default_label;
            return product.basket_button_data;
        },

        multiUserPostfix() {
            if(this.multiuserId) {
                const fullname = this.getMultiuserFullName(this.multiuserId);
                
                if(fullname) {
                    return ' for ' + fullname;
                }
            }
            
            return '';
        },
        
        getMultiuserFullName(multiuserID) {
            
            const multiusers = this.$store.state.assessment.multiusers;
            
            if(!_.isEmpty(multiusers)) {
                
                let userFullname = '';
                
                for(const prop in multiusers) {
                    
                    const user = multiusers[prop];
                    
                    if(user.id == multiuserID) {
                        userFullname = user.name;
                    }
                    
                }
                
                if(userFullname.length) {
                    return userFullname;
                }
            }
            
            return false;
            
        },
        
        collapse() {
            this.sectionCollapsed = !this.sectionCollapsed;
        },
        
        optionSelected(productID) {
            this.setProductSelected(productID, true);
        },

        optionDeselected(productID) {
            this.setProductSelected(productID, false);
        },
        
        setProductSelected(productID, selected) {
            
            const _this = this;
            
            for(const prop in _this.products) {
                
                if (_this.products[prop].id == productID) {

                    _this.products[prop].selected = selected;

                }
                
            }

            let productUserProp = 0;

            if(this.multiuserId) {
                productUserProp = this.multiuserId;
            }


            this.$store.dispatch('setAssessmentSelectedProducts', {
                productProp: productUserProp,
                products: _this.products
            });

            this.updateSelectedProductsError();
 
            this.$emit('option-changed');
            
        },

        optionSizeSelected(productID, sizeID) {
            this.setSizeSelected(productID, sizeID, true);
        },

        optionSizeDeselected(productID, sizeID) {
            this.setSizeSelected(productID, sizeID, false);
        },

        setSizeSelected(productID, sizeID, selected) {

            const _this = this;

            for(const prop in _this.products) {

                if(productID > 0 && _this.products[prop].id == productID) {
     
                        for(const sizeProp in _this.products[prop].sizes) {
                
                            if( (sizeID > 0) && (_this.products[prop].sizes[sizeProp].id == sizeID) ) {
                         
                                _this.products[prop].sizes[sizeProp].selected = selected;
                                
                            }

                        }
                        

                }

            }

            let productUserProp = 0;
            
            if(this.multiuserId) {
                productUserProp = this.multiuserId;
            }
            
            this.$store.dispatch('setAssessmentSelectedProducts', {
                productProp: productUserProp,
                products: _this.products
            });
        
            this.updateSelectedProductsError();
            
            this.$emit('option-changed');
        },

        sizeHasStock(size) {
            
            const stock = parseInt(size.stock);
            
            if(!isNaN(stock)) {
                
                return stock > 0
                
            }
            return false;
            
        },
        
        sizePrice(size) {
            
            let price = parseFloat(size.price),
                  salePrice = parseFloat(size.sale_price);

            price = !isNaN(price) ? price : 0;
            salePrice = !isNaN(salePrice) ? salePrice : 0;
            
            if(salePrice < price && salePrice > 0) {
                return salePrice;
            }

            return price;
            
        },

        singlePrice(product) {

            let price = parseFloat(product.price),
                    salePrice = parseFloat(product.sale_price);

            price = !isNaN(price) ? price : 0;
            salePrice = !isNaN(salePrice) ? salePrice : 0;

            if(salePrice < price && salePrice > 0) {
                return salePrice;
            }

            return price;

        },

        isSizeEnabled(size) {
            
            const hasStock = this.sizeHasStock(size),
                  price = parseFloat(this.sizePrice(size));
            
            return hasStock && (price > 0);
            
        },

        updateSelectedProductsError() {
            
            if(!_.isUndefined(this.$store.state.assessment.selected_products[this.multiuserId])) {
                
                const selectedItems = this.$store.state.assessment.selected_products[this.multiuserId];

                if (Object.keys(selectedItems).length) {
                    this.hasSelectionError = false;
                    return false;
                }

            }

            this.hasSelectionError = true;
            return true;

        },
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .productListings {
        
        margin: 0 0 25px;
        
        .listingsWrapper {
            
            .listingsTitle {
                
                margin: 0 0 37.8px;
                
                h2 {
                    .rems(22);
                    font-weight: 600;
                    line-height: 33px;
                    margin: 0;
                    color: @dark_primary;
                    letter-spacing: normal;
                }
                
                p {
                    margin: 0;
                    padding: 10px 0 0;
                    color: fade(@dark_primary, 80%);
                    letter-spacing: normal;
                }
            }
            
        }
        
        .productsRecommendedWrapper {
            padding: 50px 55px 0 55px;
            margin: 0 0 113.5px;
            background: fade(@primary_color, 20%);
            border-radius: 19px;

            .productList {
                margin: 0;
                
                & > li {
                    margin-bottom: 59px;
                }
            }
        }
        
        .productList {
            
            margin: 0;
            float: none;
            clear: both;
            width: 100%;
            
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 49.5px;
            
            &:before, &:after {
                display: none;
            }
            
            & > li {
                list-style: none;
                display: block;
                
                .productCard {
                    width: 100%;
                    max-width: 100%;
                }
            }
            
            
        }
        
        .noTreatments {
            padding: 24px 0;
            text-align: center;
            .rems(16);
            line-height: 1.2em;
            font-weight: 700;
            
        }
   
        
        .Splide {
            display: flex;
            flex-wrap: nowrap;
        }

        .SplideSlide {
            width: 50%;
            min-width: 0;
            max-width: none;
        }
        
        .splide {
            
            position: relative;
            margin: 0;
        
            
        }

        .splide__list {


            .productCard {
                max-width: 465px;
            }

        }

        .splide__arrows {
            

            .splide__arrow {
                background: transparent !important;
                width: 51px;
                height: 67px;
                padding: 0;
                
                &[disabled] {
                    display: none;
                }
           
                svg {
                    fill: @dark_primary;
                    width: 51px;
                    height: 67px;
                    
                    path {
                        fill: @dark_primary !important;
                    }
                }
                
                &:after {
                    display: none;
                }
            }
            
            .splide__arrow--prev {

                left: auto;
                right: 100%;
                margin-right: 8px;

            }

            .splide__arrow--next {

                left: 100%;
                right: auto;
                margin-left: 8px;

            }

        }
        
        
    }
    
    

</style>
