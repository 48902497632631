var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "productListings" },
    [
      _vm.selectionEnabled
        ? [
            _vm.hasRecommendedItems
              ? _c(
                  "section",
                  { staticClass: "listingsWrapper productsRecommendedWrapper" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "productList productsRecommended" },
                      [
                        _vm._l(_vm.products, function (product) {
                          return product.recommended_from_answers
                            ? [
                                _c(
                                  "li",
                                  { key: "product_id_" + product.id },
                                  [
                                    _c("product-card", {
                                      attrs: {
                                        product: product,
                                        "in-stock": product.in_stock,
                                        "from-price": product.from_price,
                                        large: true,
                                        "basket-button-data":
                                          _vm.basketButtonData(product),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasOtherSuitableItems
              ? _c(
                  "section",
                  { staticClass: "listingsWrapper productsSuitableWrapper" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "productList productsSuitable" },
                      [
                        _vm._l(_vm.products, function (product) {
                          return !product.recommended_from_answers
                            ? [
                                _c(
                                  "li",
                                  { key: "product_id_" + product.id },
                                  [
                                    _c("product-card", {
                                      attrs: {
                                        product: product,
                                        "from-price": product.from_price,
                                        "in-stock": product.in_stock,
                                        large: true,
                                        "basket-button-data":
                                          _vm.basketButtonData(product),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.products?.length
              ? _c("section", { staticClass: "listingsWrapper" }, [_vm._m(2)])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "listingsTitle" }, [
      _c("h2", [_vm._v("Recommended")]),
      _vm._v(" "),
      _c("p", [_vm._v("Based on our assessment, this is our recommendation")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "listingsTitle" }, [
      _c("h2", [_vm._v("Suitable Treatments")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Based on our assessment, these are the suitable items we've found for you"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "noTreatments" }, [
      _c("p", [
        _vm._v(
          "\n                    Unfortunately we cannot offer you any treatments for this condition. "
        ),
        _c("br"),
        _vm._v("\n                    Please feel free to "),
        _c("a", { attrs: { href: "/contact", title: "Contact us for help" } }, [
          _vm._v("contact us"),
        ]),
        _vm._v(" for more information.\n                "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }