var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "assessmentPageWrapper" },
    [
      _vm.setForm.form_title?.length
        ? [
            _c("div", { staticClass: "titleBanner withImage leftAlign" }, [
              _c("div", { staticClass: "wrapper" }, [
                _c("div", { staticClass: "innerTitleBanner" }, [
                  _c("div", { staticClass: "bannerContentWrap" }, [
                    _c(
                      "div",
                      { staticClass: "innerContentWrapper" },
                      [
                        _c("page-title", {
                          attrs: {
                            title: _vm.setForm.form_title,
                            content: "",
                            "css-class": "browseTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "imageBackground" }, [
                _c(
                  "picture",
                  { staticClass: "imageWrap" },
                  [
                    _vm.setForm?.form_image?.src?.length
                      ? [
                          _c("img", {
                            attrs: {
                              src: _vm.setForm?.form_image?.src,
                              alt: _vm.setForm.form_image.alt,
                            },
                          }),
                        ]
                      : [
                          _c("img", {
                            attrs: {
                              src: "/sites/default/images/placeholders/default-hero.png",
                              alt: "",
                            },
                          }),
                        ],
                  ],
                  2
                ),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "main",
          {
            staticClass: "assessmentForm",
            class: [
              _vm.loading ? "isLoading" : "",
              _vm.showFormInfo ? "" : "fullWidthContent",
            ],
          },
          [
            _c("form-content", {
              attrs: {
                "url-get-multiuser-questions": _vm.urlGetMultiuserQuestions,
                "url-submit-assessment": _vm.urlSubmitAssessment,
                "url-get-allowed-products": _vm.urlGetAllowedProducts,
                "url-add-to-basket": _vm.urlAddToBasket,
                "form-id": _vm.form.id,
                loading: _vm.loading,
                "category-id": _vm.categoryId,
                "product-id": _vm.productId,
                "previously-completed": _vm.form.previously_complete,
              },
            }),
            _vm._v(" "),
            _vm.showFormInfo ? _c("form-sidebar-info") : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "loading" },
              [
                _c("loading-spinner", {
                  attrs: {
                    loading: _vm.loading,
                    "loading-text": _vm.loadingMessage,
                    "show-loading-text": true,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }