var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bmiCalculator" }, [
    _c("div", { staticClass: "bmiUnitType" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "optionButtons radioButtons" }, [
          _c("div", { staticClass: "labelButton" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.type,
                  expression: "type",
                },
              ],
              attrs: {
                type: "radio",
                name: "bmi[type]",
                id: "question_" + _vm.question.id + "bmi_type_metric",
                value: "Metric",
              },
              domProps: {
                checked: _vm.type == "Metric",
                checked: _vm._q(_vm.type, "Metric"),
              },
              on: {
                change: function ($event) {
                  _vm.type = "Metric"
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                attrs: {
                  for: "question_" + _vm.question.id + "bmi_type_metric",
                },
              },
              [_vm._v("\n                        Metric\n                    ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "labelButton" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.type,
                  expression: "type",
                },
              ],
              attrs: {
                type: "radio",
                name: "bmi[type]",
                id: "question_" + _vm.question.id + "bmi_type_imperial",
                value: "Imperial",
              },
              domProps: {
                checked: _vm.type == "Imperial",
                checked: _vm._q(_vm.type, "Imperial"),
              },
              on: {
                change: function ($event) {
                  _vm.type = "Imperial"
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                attrs: {
                  for: "question_" + _vm.question.id + "bmi_type_imperial",
                },
              },
              [
                _vm._v(
                  "\n                        Imperial\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "bmiCalculatorFields" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isType("Metric"),
              expression: "isType('Metric')",
            },
          ],
          staticClass: "bmiFields bmiMetricFields",
        },
        [
          _c("fieldset", { staticClass: "bmiFieldset" }, [
            _c("legend", [_vm._v("Height")]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "inputWrap" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "question_" + _vm.question.id + "_bmi_height_cm",
                    },
                  },
                  [_vm._v("cm")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.heightCm,
                      expression: "heightCm",
                    },
                  ],
                  attrs: {
                    id: "question_" + _vm.question.id + "_bmi_height_cm",
                    type: "number",
                    step: "1",
                    min: "0",
                    max: "300",
                    name:
                      "question[" +
                      _vm.question.id +
                      "][bmi][metric][height_cm]",
                  },
                  domProps: { value: _vm.heightCm },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.heightCm = $event.target.value
                      },
                      function ($event) {
                        return _vm.update("heightCm")
                      },
                    ],
                    change: function ($event) {
                      _vm.heightCmChanged = true
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", { staticClass: "bmiFieldset" }, [
            _c("legend", [_vm._v("Weight")]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "inputWrap" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "question_" + _vm.question.id + "_bmi_height_kg",
                    },
                  },
                  [_vm._v("kg")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.weightKg,
                      expression: "weightKg",
                    },
                  ],
                  attrs: {
                    id: "question_" + _vm.question.id + "_bmi_height_kg",
                    type: "number",
                    step: "1",
                    min: "0",
                    max: "800",
                    name:
                      "question[" +
                      _vm.question.id +
                      "][bmi][metric][weight_kg]",
                  },
                  domProps: { value: _vm.weightKg },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.weightKg = $event.target.value
                      },
                      function ($event) {
                        return _vm.update("weightKg")
                      },
                    ],
                    change: function ($event) {
                      _vm.weightKgChanged = true
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isType("Imperial"),
              expression: "isType('Imperial')",
            },
          ],
          staticClass: "bmiFields bmiMetricFields",
        },
        [
          _c("fieldset", { staticClass: "bmiFieldset" }, [
            _c("legend", [_vm._v("Height")]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "inputWrap" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "question_" + _vm.question.id + "_bmi_height_feet",
                    },
                  },
                  [_vm._v("feet")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.heightFeet,
                      expression: "heightFeet",
                    },
                  ],
                  attrs: {
                    id: "question_" + _vm.question.id + "_bmi_height_feet",
                    type: "number",
                    step: "1",
                    min: "0",
                    max: "10",
                    name:
                      "question[" +
                      _vm.question.id +
                      "][bmi][imperial][height_feet]",
                  },
                  domProps: { value: _vm.heightFeet },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.heightFeet = $event.target.value
                      },
                      function ($event) {
                        return _vm.update("heightFeet")
                      },
                    ],
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "inputWrap" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "question_" + _vm.question.id + "_bmi_height_inches",
                    },
                  },
                  [_vm._v("inches")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.heightInches,
                      expression: "heightInches",
                    },
                  ],
                  attrs: {
                    id: "question_" + _vm.question.id + "_bmi_height_inches",
                    type: "number",
                    step: "1",
                    min: "0",
                    max: "12",
                    name:
                      "question[" +
                      _vm.question.id +
                      "][bmi][imperial][height_inches]",
                  },
                  domProps: { value: _vm.heightInches },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.heightInches = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", { staticClass: "bmiFieldset" }, [
            _c("legend", [_vm._v("Weight")]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "inputWrap" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "question_" + _vm.question.id + "_bmi_height_stone",
                    },
                  },
                  [_vm._v("stone")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.weightStone,
                      expression: "weightStone",
                    },
                  ],
                  attrs: {
                    id: "question_" + _vm.question.id + "_bmi_height_stone",
                    type: "number",
                    step: "1",
                    min: "0",
                    max: "100",
                    name:
                      "question[" +
                      _vm.question.id +
                      "][bmi][imperial][weight_stone]",
                  },
                  domProps: { value: _vm.weightStone },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.weightStone = $event.target.value
                      },
                      function ($event) {
                        return _vm.update("weightStone")
                      },
                    ],
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "inputWrap" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "question_" + _vm.question.id + "_bmi_height_pounds",
                    },
                  },
                  [_vm._v("pounds")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.weightPounds,
                      expression: "weightPounds",
                    },
                  ],
                  attrs: {
                    id: "question_" + _vm.question.id + "_bmi_height_pounds",
                    type: "number",
                    step: "1",
                    min: "0",
                    max: "13",
                    name:
                      "question[" +
                      _vm.question.id +
                      "][bmi][imperial][weight_pounds]",
                  },
                  domProps: { value: _vm.weightPounds },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.weightPounds = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.bmiCalculation > 0 && !_vm.question.settings.hidden_input,
            expression: "bmiCalculation > 0 && !question.settings.hidden_input",
          },
        ],
        staticClass: "bmiCalculation",
      },
      [
        _vm._v("\n\n        Your BMI is: "),
        _c("span", { domProps: { innerHTML: _vm._s(_vm.bmiCalculation) } }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.bmiCalculation,
              expression: "bmiCalculation",
            },
          ],
          attrs: { type: "hidden", name: _vm.questionName + "[bmi][value]" },
          domProps: { value: _vm.bmiCalculation },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.bmiCalculation = $event.target.value
            },
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }