var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "travelUsersWrap" }, [
    _vm.userList.length
      ? _c("div", { staticClass: "travelUsers" }, [
          _c("div", { staticClass: "userTitle" }, [
            _vm._v("\n            Traveller(s)\n        "),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "userList" },
            [
              _vm._l(_vm.userList, function (user, index) {
                return [
                  _c(
                    "li",
                    { key: "travel_user_" + index, staticClass: "userRow" },
                    [
                      _c("span", { staticClass: "userNameText" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              user.name + " (" + user.dob + ")"
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "removeIcon",
                          attrs: { role: "button", title: "Remove user" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeUser(index)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "xmark"] },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "users[" + index + "][name]",
                        },
                        domProps: { value: user.name },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "users[" + index + "][dob]",
                        },
                        domProps: { value: user.dob },
                      }),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "textRow" }, [
      _c("div", { staticClass: "row" }, [
        _c("label", { attrs: { for: "travel_q_id_" + _vm.question.id } }, [
          _vm._v("Traveller's name"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.userName,
              expression: "userName",
            },
          ],
          attrs: {
            type: "text",
            name: _vm.question.name,
            id: "travel_q_id_" + _vm.question.id,
            placeholder: _vm.placeholderName,
            autocomplete: "off",
          },
          domProps: { value: _vm.userName },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.userName = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("label", { attrs: { for: "travel_q_id_" + _vm.question.id } }, [
          _vm._v("Traveller's date of birth"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.userDob,
              expression: "userDob",
            },
          ],
          attrs: {
            type: "text",
            name: _vm.question.dob,
            id: "travel_q_id_" + _vm.question.id,
            placeholder: _vm.placeholderDob,
            autocomplete: "off",
          },
          domProps: { value: _vm.userDob },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.userDob = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    this.users.length < 8
      ? _c("div", { staticClass: "travelUserButtonWrap" }, [
          _c(
            "button",
            {
              staticClass: "button buttonText addUserButton",
              attrs: { type: "button", title: "Add user" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addUser()
                },
              },
            },
            [
              _c("span", { staticClass: "svgIcon" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "10",
                      height: "10",
                      viewBox: "0 0 10 10",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("rect", {
                      attrs: {
                        y: "4",
                        width: "10",
                        height: "2",
                        rx: "1",
                        fill: "currentColor",
                      },
                    }),
                    _vm._v(" "),
                    _c("rect", {
                      attrs: {
                        x: "6",
                        width: "10",
                        height: "2",
                        rx: "1",
                        transform: "rotate(90 6 0)",
                        fill: "currentColor",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "buttonText" }, [
                _vm._v("\n                add traveller\n            "),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }