var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.question.visible
    ? _c(
        "div",
        {
          staticClass: "questionWrap",
          class: _vm.validationClass,
          attrs: { id: "question_wrap_" + _vm.question.id },
        },
        [
          _c("div", { staticClass: "row" }, [
            _vm.questionNumber
              ? _c("div", { staticClass: "questionNumberCol" }, [
                  _c("div", { staticClass: "questionNumber" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.questionNumber) },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("label", {
              staticClass: "questionText",
              attrs: { for: "question_" + _vm.question.id },
              domProps: { innerHTML: _vm._s(_vm.question.question_text) },
            }),
            _vm._v(" "),
            _vm.hasError
              ? _c("div", { staticClass: "formError" }, [
                  _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.errorMessage) },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "inputWrap" },
              [
                _vm.matchType("yes_no")
                  ? _c("yes-no", {
                      ref: "yesNo",
                      attrs: {
                        question: _vm.question,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("variations")
                  ? _c("variations", {
                      ref: "variations",
                      attrs: {
                        question: _vm.question,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("confirmation")
                  ? _c("confirmation", {
                      ref: "confirmation",
                      attrs: {
                        question: _vm.question,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("date")
                  ? _c("date-field", {
                      ref: "dateField",
                      attrs: {
                        question: _vm.question,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("number")
                  ? _c("number-field", {
                      ref: "number",
                      attrs: {
                        question: _vm.question,
                        "allow-input-event": _vm.changed,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("email")
                  ? _c("email-field", {
                      ref: "email",
                      attrs: {
                        question: _vm.question,
                        placeholder: "example@website.com",
                        "allow-input-event": _vm.changed,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("image")
                  ? _c("image-upload", {
                      ref: "image",
                      attrs: {
                        question: _vm.question,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("allergies")
                  ? _c("tag-input", {
                      ref: "tagInput",
                      attrs: {
                        question: _vm.question,
                        "default-tags":
                          _vm.$store.state.user.healthProfile.allergies,
                        placeholder: "Add allergy",
                        "autocomplete-items": [],
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("medications")
                  ? _c("tag-input", {
                      ref: "tagInput",
                      attrs: {
                        question: _vm.question,
                        "default-tags":
                          _vm.$store.state.user.healthProfile.medications,
                        placeholder: "Add medication",
                        "autocomplete-items": _vm.$store.state.medicationsList,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("conditions")
                  ? _c("tag-input", {
                      ref: "tagInput",
                      attrs: {
                        question: _vm.question,
                        "default-tags":
                          _vm.$store.state.user.healthProfile.conditions,
                        placeholder: "Add condition",
                        "autocomplete-items":
                          _vm.$store.state.medicalConditionList,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("textarea")
                  ? _c("textarea-field", {
                      ref: "text",
                      attrs: {
                        question: _vm.question,
                        placeholder: "",
                        "allow-input-event": _vm.changed,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("notify_gp")
                  ? _c("notify-gp", {
                      ref: "notifyGP",
                      attrs: {
                        question: _vm.question,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("bmi")
                  ? _c("bmi-calculator", {
                      ref: "bmi",
                      attrs: {
                        question: _vm.question,
                        "set-type": _vm.question.bmi_field_values.type,
                        "set-height-cm":
                          _vm.question.bmi_field_values.height_cm,
                        "set-weight-kg":
                          _vm.question.bmi_field_values.weight_kg,
                        "set-height-feet":
                          _vm.question.bmi_field_values.height_feet,
                        "set-height-inches":
                          _vm.question.bmi_field_values.height_inches,
                        "set-weight-stone":
                          _vm.question.bmi_field_values.weight_stone,
                        "set-weight-pounds":
                          _vm.question.bmi_field_values.weight_pounds,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("weight")
                  ? _c("weight-field", {
                      ref: "weight",
                      attrs: {
                        question: _vm.question,
                        "set-type": _vm.question.weight_field_values.type,
                        "set-weight-kg":
                          _vm.question.weight_field_values.weight_kg,
                        "set-weight-stone":
                          _vm.question.weight_field_values.weight_stone,
                        "set-weight-pounds":
                          _vm.question.weight_field_values.weight_pounds,
                        "multiuser-id": _vm.multiuserId,
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("travel_users")
                  ? _c("travel-users", {
                      ref: "travel_users",
                      attrs: {
                        question: _vm.question,
                        "placeholder-name": "John Smith",
                        "placeholder-dob": "dd/mm/yyyy",
                      },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("travel_dates")
                  ? _c("travel-dates", {
                      ref: "travel_dates",
                      attrs: { question: _vm.question },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm.matchType("travel_destinations")
                  ? _c("travel-destinations", {
                      ref: "travel_destinations",
                      attrs: { question: _vm.question },
                      on: { "update-question": _vm.updateQuestion },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.matchType("travel_destinations") &&
                !_vm.matchType("travel_dates") &&
                !_vm.matchType("travel_users")
                  ? [
                      _vm.multiuserId
                        ? [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.question.answer_change_count,
                                  expression: "question.answer_change_count",
                                },
                              ],
                              attrs: {
                                type: "hidden",
                                name:
                                  "change_count[" +
                                  _vm.multiuserId +
                                  "][" +
                                  _vm.question.original_id +
                                  "]",
                              },
                              domProps: {
                                value: _vm.question.answer_change_count,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.question,
                                    "answer_change_count",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        : [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.question.answer_change_count,
                                  expression: "question.answer_change_count",
                                },
                              ],
                              attrs: {
                                type: "hidden",
                                name: "change_count[" + _vm.question.id + "]",
                              },
                              domProps: {
                                value: _vm.question.answer_change_count,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.question,
                                    "answer_change_count",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ],
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }