<template>
    
  <section class="productsGrouped">

      
      <template  v-for="(group, groupIndex) in groupedProducts">

          <div
                  class="productsGrouped__group"
                  v-if="group.products.length"
                  :key="'productGroup' + groupIndex"
          >

              <div 
                      v-html="group.data.name" 
                      v-if="group.data.name && parentCategory.id"
                      class="productsGrouped__title"
              ></div>

              <product-list
                      :loading="loading"
                      :products="group.products"
                      :parent-class="['categoryProducts']"
                      :list-class="['categoryProductList']"
                      :card-previews="true"
                      :minimal="false"
                      :card-class="[]"
                      :slider="true"
              ></product-list>

          </div> <!-- .productsGrouped__Group -->
          
      </template>
      
      
      
  </section>
    
</template>

<script>

import productList from './product_list';

export default {
    name: "productsGrouped",
    components: {
        productList
    },
    props: {
        setProducts: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        setParentCategory: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        loading: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            products: [],
            parentCategory: {
                id: false,
                name: false
            },
            groupedProducts: []
        }
    },
    created() {
        this.products = this.setProducts;
        
        if(typeof this.setParentCategory.id != 'undefined') {
            this.parentCategory = this.setParentCategory;
        }
        
        this.groupedProducts = this.group(this.products);
    },
    methods: {
        
        group(products) {
            
            const groups = [];
            
            products.forEach(product => {
             
                let subCategoryID = product.main_sub_category.id,
                    subCategoryName = product.main_sub_category.name;
                
                if(!subCategoryID) {
                    subCategoryID = 0;
                }

                if(typeof groups[subCategoryID] == 'undefined') {
                    
                    groups[subCategoryID] = {
                        data: {
                            id: subCategoryID,
                            name: subCategoryName
                        },
                        products: []
                    };

                }

                groups[subCategoryID].products.push(product);
                
                
            });

            const groupsClean = [];
            
            for (const [key, value] of Object.entries(groups)) {
                groupsClean.push(value);
            }
            
            return groupsClean;
            
        },
        
        hasProducts(products) {
            
            if(typeof products != 'undefined') {
                return products.length;
            }
            
            return false;
            
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    @import '@site/../default/css/base/font.less';
    
    .productsGrouped {
        
        &__title {
            font-family: @primary_font;
            color: @text_color;
            .rems(20);
            line-height: 32px;
            margin: 0 0 16px;
            font-weight: 600;
        }
        
    }
    

</style>
