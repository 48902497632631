$(document).ready(function () {

    
    if($('.mainMenu').length) {
        showHideMenus('.mainMenu .hasChildren > a', 1120);
    }
    


});


$(window).on('resize', debouncer(function (e) {


    if($('.mainMenu').length) {
        showHideMenus('.mainMenu .hasChildren > a', 1120);
    }
    

})); // debounce



/*
 * Trigger section below breakpoint. Selector is the trigger
 */

window.showHideMenus = function(selector, breakpoint) {
    'use strict';

    var $selector = $(selector);

    $selector.each(function(){

        var trigger = $(this);
        var section = trigger.next();

        if (typeof (trigger.data('target')) !== 'undefined') {
            section = $(trigger.data('target'));
        }

        if(section.length > 0 && !trigger.hasClass('directLink')) {
            // mobile: show / hide behaviour
            if (matchesMediaQuery(0, breakpoint) && !trigger.hasClass('isClickable')) {

                // initialise show / hide state
                section.hide();
                trigger.addClass('isClickable');
                // bind click
                $(trigger).on('click', function (e) {

                    var this_section = $(this).next(),
                        this_trigger = $(this);

                    e.preventDefault();

                    if (typeof (this_trigger.data('target')) !== 'undefined') {
                        this_section = $(this_trigger.data('target'));
                    }

                    this_section.toggleClass('open').stop(true, true).slideFadeToggle();
                    this_trigger.toggleClass('open');
                });

            } else if (matchesMediaQuery(Number(breakpoint + 1)) && trigger.hasClass('isClickable')) {
                trigger.removeClass('isClickable');
                // disable click and remove classes
                trigger.removeClass('open').off('click');
                section.removeClass('open').css({
                    display: ''
                });
            }
        }

    });

}


window.addEventListener('DOMContentLoaded', function(event) {

    'use strict';

    if($('.megaMenuWrapper').length) {
        $('.megaMenuWrapper').addClass('menuReady');
    }


});
