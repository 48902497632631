<template>

    <dialog class="travelQuantityAdjust" v-if="isVisible">

        <div class="dialogBody">
            
            <header>
                
                <div class="headerImage" v-if="product">
                    <product-images
                            :images="product.images"
                            :lazy-load="true"
                            size="thumbs"
                            :slideshow="false"
                            :show-arrows="false"
                            :show-thumbs="false"
                            :allow-zoom="false"
                    ></product-images>
                </div> <!-- .headerImage -->

                <div class="headerTitle">
                    <h3 v-html="dialogTitle"></h3>
                    
                    <button 
                            type="button" 
                            aria-label="Close quanitity adjustment" 
                            class="closeButton"
                            @click.prevent="closeOverlay(true)"
                    >
                        <i>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1.41406" width="25" height="2" rx="1" transform="rotate(45 1.41406 0)" fill="currentColor"/>
                                <rect y="17.6777" width="25" height="2" rx="1" transform="rotate(-45 0 17.6777)" fill="currentColor"/>
                            </svg>

                        </i>
                    </button>
                </div> <!-- .headerTitle -->
                
            </header>
    
            <div class="dialogContent">
                
                <label class="dialogContentTitle">Adjust medication quantity</label>

                <div class="wysiwyg">
                    
                    <p>
                        Want to adjust the amount prescribed?  Do you have a few tablets spare from a previous trip, 
                        or want to purchase a few extras just in case? No problem, just increase or decrease the 
                        number below:
                    </p>

                </div>

                <div class="quantitySelectionWrap">

                    <div class="selectionLeft">
                        <p>
                            Recommended quantity based on your travel dates: <span v-html="quantityString"></span>
                        </p>
                    </div> <!-- .selectionLeft -->

                    <div class="selectionRight">

                        <div class="row">

                            <mtc-number-input
                                    :max="9999"
                                    :min="1"
                                    :value="quantity"
                                    :name="'product_quantity'"
                                    @input="updateQuantity"
                            ></mtc-number-input>
                            
                        </div> <!-- .row -->
                        
                    </div> <!-- .selectionRight -->
                    
                </div> <!-- .quantitySelectionWrap -->

                <div class="quantityConfirm">

                    <div class="row">
                        
                        <input type="checkbox"
                               name="product_quantity_confirm" 
                               id="product_quantity_confirm" 
                               v-model="confirmed"
                        >

                        <label for="product_quantity_confirm">
                            I confirm that I have enough spare tablets that are within
                            their use-by-date to cover the duration of my trip.
                        </label>
                        
                    </div> <!-- .row -->
                    
                </div> <!-- .quantityConfirm -->
                
            </div> <!-- .dialogBody -->
            
            <footer>
                
                <button 
                        type="button" 
                        class="button" 
                        :disabled="(!confirmed || (quantity < 1))"
                        @click.prevent="submitQuantity"
                >
                    Confirm adjusted quantity
                </button>
                
            </footer>

        </div>

    </dialog>
        


</template>

<script>

import MtcNumberInput from '../../../mtc_number_input'

export default {
    name: "travel-quantity-adjust",
    components: {
        MtcNumberInput
    },
    props: {
        dialogTitle: {
            type: [String],
            default (rawProps) {
                return 'Select quantity';
            }
        },
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        isVisible: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        setQuantity: {
            type: [Number, String],
            default (rawProps) {
                return 1;
            }
        }
    },
    data() {
        return {
            quantity: 1,
            confirmed: false
        }
    },
    computed: {
        quantityString() {

            let qty = this.qtyToInt(this.quantity);
            
            if(this.quantity > 1) {
                return qty + ' Tablets';
            }else{
                return qty + ' Tablet';
            }
        }
    },
    created() {
        this.setQuantityNumber();
    },
    methods: {
        setQuantityNumber() {
            
            this.quantity = this.qtyToInt(this.setQuantity);
            
            if(this.quantity < 1) {
                this.quantity = 1;
            }
            
        },
        
        updateQuantity(qty) {
            
            qty = this.qtyToInt(qty);
            
            if(qty < 1) {
                qty = 1;
            }
            this.quantity = qty;
        },
        submitQuantity() {

            if( this.qtyToInt(this.quantity) < 1) {
                this.quantity = 1;
            }
            
            this.$emit('quantity-updated', this.quantity);
        },
        closeOverlay(bypassQuantityChange) {
            
            if(_.isUndefined(bypassQuantityChange)) {
                bypassQuantityChange = false;
            }

            if(bypassQuantityChange) {
          
                this.$emit('quantity-cancelled');
                
            }else {
              
                if (this.qtyToInt(this.quantity) < 1) {
                    this.quantity = 1;
                }

                this.$emit('quantity-closed', this.quantity);
            }
        },
        
        qtyToInt(qty) {

            let qtyInt = parseInt(qty);
            
            if(isNaN(qtyInt)) {
                return 1;
            }
            
            return qtyInt;
            
            
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

   .travelQuantityAdjust {
       
       display: flex;
       align-items: center;
       justify-content: center;
       width: 100vw;
       height: 100vh;
       position: fixed;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       margin: 0;
       z-index: 9999999999;
       border: none;
       overflow: auto;
       background: rgba(0,0,0,0.24);
       
       @media @mobile {
           align-items: flex-start;
       }
       
       @media all and (max-width: 450px) {
           overflow: scroll;
       }

       &::backdrop {
           background-color: rgba(0,0,0,0);
       }
       
       .dialogBody {
           background: #fff;
           flex: 1 1 466px;
           max-width: 466px;
           border-radius: 10px;
           overflow: hidden;
           margin: 25px;
           
           @media @mobile {
               margin: 25px 0;
           }
       }
       
       header {
           background: @primary_color;
           color: #fff;
           display: flex;
           position: relative;
           padding-right: 32px;
       }
       
       .headerImage {
           flex: 0 0 100px;
           min-height: 100px;
           background: @dark_color;
           border-radius: 5px 0 0 0;
           overflow: hidden;
           position: relative;
           
           .productImage {

               position: absolute;
               width: 100%;
               height: 100%;
               top: 0;
               left: 0;
               right: 0;
               bottom: 0;
               padding: 0;
               
               & > .mainImage {
                   
                   position: absolute;
                   width: 100%;
                   height: 100%;
                   top: 0;
                   left: 0;
                   right: 0;
                   bottom: 0;
                   border-radius: 0;
                   padding: 0;
                   
                   & > img {
                       position: absolute;
                       width: 100%;
                       height: 100%;
                       object-fit: cover;
                   }

               }

           }
       }
       
       .headerTitle {
           flex: 1 1 100%;
           display: flex;
           align-items: center;
           justify-content: flex-start;
           padding: 25px 70px 25px 35px;
           
           @media @mobile {
               padding: 25px 70px 25px 20px;
           }

           h3 {
               color: #fff;
               .rems(18);
               line-height: 1.2em;
               font-weight: 700;
               margin: 0;
           }
           
           .closeButton {
               background: transparent;
               border: none;
               color: #fff;
               position: absolute;
               width: 48px;
               height: 48px;
               top: 16px;
               right: 12px;
               padding: 0;
               transition: opacity 200ms linear;
               cursor: pointer;
               
               &:hover, &:active, &:focus {
                   opacity: 0.8;
                   color: #fff;
                   background: transparent;
                   border: none;
                   transition: opacity 200ms linear;
               }
               
               i {
                   position: absolute;
                   top: 0;
                   right: 0;
                   bottom: 0;
                   left: 0;
                   width: 100%;
                   height: 100%;
                   display: block;
                   margin: 0;
                   padding: 0;
               }
               
               svg {
                   position: absolute;
                   width: 19px;
                   height: 19px;
                   top: 50%;
                   left: 50%;
                   transform: translate(-50%,-50%);
               }
           }
       }
       
       .dialogContent {
           padding: 35px;
           
           @media @mobile {
               padding: 35px 25px 25px 25px;
           }
       }

       .dialogContentTitle {
           color: @text_color;
           .rems(18);
           line-height: 1.2em;
           font-weight: 700;
           margin: 0 0 15px;
           text-align: center;
           display: block;
           width: 100%;

           @media @mobile {
               margin: 0 0 10px;
           }
       }
       
       .wysiwyg {
           text-align: center;
           padding: 0 0 35px 0;

           @media @mobile {
               padding: 0 0 18px 0;
           }
           
           p {

               font-style: normal;
               font-weight: 500;
               .rems(14);
               line-height: 1.5em;
               text-transform: none;
               
               
               &:last-of-type {
                   margin: 0;
               }
           }
       }
       
       .quantitySelectionWrap {
           display: flex;
           padding: 20px 25px;
           gap: 0 35px;
           border: 1.5px solid @primary_color;
           border-radius: 5px;
           
           @media all and (max-width: 450px) {
               flex-wrap: wrap;
           }
       }

       .selectionLeft {
            flex: 1 1 100%;
           
           p {
               font-weight: 600;
               .rems(14);
               line-height: 1.5em;
               letter-spacing: 0.1em;
               text-transform: uppercase;
               margin: 0;

               @media all and (max-width: 450px) {
                   text-align: center;
               }
           }
       }

       .selectionRight {
            flex: 0 0 90px;
           display: flex;
           align-items: center;
           justify-content: flex-end;

           @media all and (max-width: 450px) {
               padding-top: 15px;
               margin: 0 auto;
           }
           
           .row {
               margin: 0;
               padding: 0;
               float: none;
               clear: both;
               
               input {
                   .rems(16);
                   line-height: 24px;
                   font-weight: 600;
               }
           }
       }
       
       .quantityConfirm {
           
           padding: 25px 0 0 0;
           
           @media @mobile {
               padding: 15px 0 0;
           }
           
           .row {
               margin: 0;
               padding: 0;
               float: none;
               clear: both;
               
               label {
                   font-weight: 700  !important;
                   .rems(12) !important;
                   line-height: 1.2em  !important;
                   letter-spacing: 0.1em  !important;
                   text-transform: uppercase  !important;
                   padding-top: 0 !important;
                   padding-bottom: 0 !important;
                   border-width: 1.5px !important;
                   
                   
                   
                   &:before {
                       top: 3px !important;
                       border-color: @text_color !important;
                       transition: all 200ms linear;
                   }

                   &:after {
                       top: 3px !important;
                   }

                   &:hover, &:focus, &:active {

                       &:before {
                           border-color: @secondary_color !important;
                           transition: all 200ms linear;
                       }
                   }
                   
               }
               
               
               input:checked + label {

                   &:before {
                       border-color: @secondary_color !important;
                       transition: all 200ms linear;
                   }
                   
                   &:hover, &:focus, &:active {

                       &:before {
                           border-color: @secondary_color !important;
                           transition: all 200ms linear;
                       }
                   }
                   
               }
           }
           
       }
       
       footer {
           padding: 0 35px 35px;
           
           .button {
               margin: 0;
               width: 100%;
           }
       }
       
   }
    
    
</style>
