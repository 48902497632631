var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.trustpilotBusinessID
    ? _c(
        "div",
        {
          staticClass: "trustpilotCardWrapper",
          class: [_vm.trustpilotReady ? "trustpilotReady" : ""],
        },
        [
          _c("a", { attrs: { href: _vm.trustpilotLink, target: "_blank" } }, [
            _c(
              "div",
              {
                ref: "trustbox",
                staticClass: "trustpilot-widget",
                attrs: {
                  "data-locale": "en-GB",
                  "data-template-id": _vm.trustpilotTemplateID,
                  "data-businessunit-id": _vm.trustpilotBusinessID,
                  "data-style-height": _vm.height,
                  "data-style-width": _vm.width,
                  "data-theme": "light",
                  "data-sku": _vm.Skus,
                  "data-font-family": "Quicksand",
                  "data-text-color": _vm.trustpilotTextColor,
                  "data-star-color": _vm.trustpilotStarColor,
                  "data-no-reviews": "show",
                  "data-scroll-to-list": "false",
                  "data-style-alignment": "left",
                },
              },
              [
                _c(
                  "span",
                  {
                    attrs: {
                      href: _vm.trustpilotLink,
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  [_vm._v("Trustpilot")]
                ),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "visuallyHidden" }, [
              _vm._v("Trustpilot"),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }