var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "assessmentSteps" }, [
    _c(
      "ul",
      { staticClass: "assessmentSteps__steps" },
      _vm._l(_vm.steps, function (step, index) {
        return _c(
          "li",
          {
            key: "step" + index,
            staticClass: "assessmentSteps__step",
            class: [index + 1 <= _vm.currentStep ? "activeStep" : ""],
          },
          [
            _c("span", {
              staticClass: "assessmentSteps__stepText",
              domProps: { innerHTML: _vm._s(step.name) },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }