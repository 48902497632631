var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "assessmentSidebarInfo" }, [
    _c("div", { staticClass: "assessmentSidebarInfo__wrap" }, [
      _c("section", { staticClass: "assessmentSidebarInfo__points" }, [
        _c("ul", [
          _c("li", [
            _c("i", { staticClass: "svgIcon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    height: "1em",
                    viewBox: "0 0 640 512",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("Discreet Delivery")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("i", { staticClass: "svgIcon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    height: "1em",
                    viewBox: "0 0 576 512",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M142.4 21.9c5.6 16.8-3.5 34.9-20.2 40.5L96 71.1V192c0 53 43 96 96 96s96-43 96-96V71.1l-26.1-8.7c-16.8-5.6-25.8-23.7-20.2-40.5s23.7-25.8 40.5-20.2l26.1 8.7C334.4 19.1 352 43.5 352 71.1V192c0 77.2-54.6 141.6-127.3 156.7C231 404.6 278.4 448 336 448c61.9 0 112-50.1 112-112V265.3c-28.3-12.3-48-40.5-48-73.3c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V336c0 97.2-78.8 176-176 176c-92.9 0-168.9-71.9-175.5-163.1C87.2 334.2 32 269.6 32 192V71.1c0-27.5 17.6-52 43.8-60.7l26.1-8.7c16.8-5.6 34.9 3.5 40.5 20.2zM480 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("Experienced Pharmacists")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("i", { staticClass: "svgIcon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    height: "1em",
                    viewBox: "0 0 384 512",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M80 0C44.7 0 16 28.7 16 64V448c0 35.3 28.7 64 64 64H304c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H80zm80 432h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("Order from anywhere")]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }