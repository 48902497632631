var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.cutoffTime > 0
    ? _c("section", { staticClass: "deliveryCountdown" }, [
        _c(
          "div",
          { staticClass: "deliveryCountdown__wrapper" },
          [
            _c("countdown", {
              staticClass: "deliveryCountdown__timer",
              attrs: { time: _vm.cutoffTime },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "div",
                          { staticClass: "deliveryCountdown__message" },
                          [
                            _vm._v(
                              "\n                    For next day delivery, order in the next\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        props.days < 1
                          ? _c(
                              "div",
                              { staticClass: "deliveryCountdown__time" },
                              [
                                props.hours > 0
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "deliveryCountdown__block",
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "deliveryCountdown__value",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.formatValues(props.hours)
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "deliveryCountdown__units",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                hrs\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                [
                                  _c(
                                    "div",
                                    { staticClass: "deliveryCountdown__block" },
                                    [
                                      _c("div", {
                                        staticClass: "deliveryCountdown__value",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.formatValues(props.minutes)
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "deliveryCountdown__units",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                mins\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                _vm._v(" "),
                                [
                                  _c(
                                    "div",
                                    { staticClass: "deliveryCountdown__block" },
                                    [
                                      _c("div", {
                                        staticClass: "deliveryCountdown__value",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.formatValues(props.seconds)
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "deliveryCountdown__units",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                secs\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                1297179221
              ),
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }