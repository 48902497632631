var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "optionButtons radioButtons" }, [
    _c("div", { staticClass: "labelButton" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.question.answer,
            expression: "question.answer",
          },
        ],
        attrs: {
          type: "radio",
          name: _vm.questionName,
          id: "radio_2_" + _vm.question.id,
          value: "Yes",
        },
        domProps: {
          checked: _vm.question.answer == "Yes",
          checked: _vm._q(_vm.question.answer, "Yes"),
        },
        on: {
          change: [
            function ($event) {
              return _vm.$set(_vm.question, "answer", "Yes")
            },
            function ($event) {
              return _vm.updateQuestion(_vm.question)
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "radio_2_" + _vm.question.id } }, [
        _vm._v("Yes"),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "labelButton" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.question.answer,
            expression: "question.answer",
          },
        ],
        attrs: {
          type: "radio",
          name: _vm.questionName,
          id: "id_1_" + _vm.question.id,
          value: "No",
        },
        domProps: {
          checked: _vm.question.answer == "No",
          checked: _vm._q(_vm.question.answer, "No"),
        },
        on: {
          change: [
            function ($event) {
              return _vm.$set(_vm.question, "answer", "No")
            },
            function ($event) {
              return _vm.updateQuestion(_vm.question)
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "id_1_" + _vm.question.id } }, [
        _vm._v("No"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }