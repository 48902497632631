<template>

    <div class="assessmentSteps">
        
        <ul class="assessmentSteps__steps">
            
            <li 
                    class="assessmentSteps__step" 
                    v-for="(step, index) in steps" 
                    :key="'step' + index"
                    :class="[(index + 1) <= currentStep  ? 'activeStep' : '']"
            >
                <span 
                        v-html="step.name" 
                        class="assessmentSteps__stepText"
                ></span>
            </li>
            
        </ul>
        
    </div> <!-- .assessmentSteps -->

</template>

<script>
export default {
    
    props: {
        step: {
            type: [Number],
            default: 1
        }
    },
    
    data() {
        return {
            currentStep: 1,
            steps: [
                {
                    name: 'Consultation'
                },
                {
                    name: 'Treatments'
                },
                {
                    name: 'Checkout'
                }
            ]
        }
    },
    
    mounted() {
        
        const vm = this;
        
        this.currentStep = this.step;

        EventBus.$on('assessmentStep', function(step) {
            vm.changeStep(step);
        });
    },
    
    methods: {
        
        changeStep(step) {
            this.currentStep = step;
        }
        
    }
    
    
}
</script>

<style lang="less" scoped>

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .assessmentSteps {
        
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        
        &__steps {
            
            margin: 0;
            padding: 0;
            display: flex;
            position: relative;
            width: ~"calc(100% + 85px)";
            margin: 0 -42.5px;
   
            
            @media @flyout {
                min-width: 0;
                width: ~"calc(100% + 40px)";
                margin: 0 -20px;
            }
            
            &:before, &:after {
                display: none;
            }
            
            
        }
        
        &__step {
            list-style: none;
            padding: 30px 42.5px 0 42.5px;
            margin: 0;
            flex: 1 1 100%;
            display: flex;
            justify-content: center;
            position: relative;

            @media @flyout {
                padding: 30px 20px 0 20px;
            }
       
            
            &:before {
                content: "";
                display: block;
                width: 22px;
                height: 22px;
                background: #E2DFD4;
                border: 4px solid #E2DFD4;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 50%;
                box-sizing: border-box;
                z-index: 2;
            }

            &:after {
                content: "";
                height: 2px;
                width: 100%;
                position: absolute;
                top: 11px;
                left: 50%;
                right: 0;
                background: #E2DFD4;
                z-index: 1;
            }
            
            &:last-child {
                &:after {
                    display: none;
                }
            }
            
            
            &.activeStep {
                
                &:before {
                    background: @primary_color;
                }
                
            }

           
        }
        
        &__stepText {
            .rems(14);
            font-weight: 500;
            line-height: normal;
            color: @primary_color;
            display: block;
            
        }
        
    }

</style>
