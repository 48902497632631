var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "productsGrouped" },
    [
      _vm._l(_vm.groupedProducts, function (group, groupIndex) {
        return [
          group.products.length
            ? _c(
                "div",
                {
                  key: "productGroup" + groupIndex,
                  staticClass: "productsGrouped__group",
                },
                [
                  group.data.name && _vm.parentCategory.id
                    ? _c("div", {
                        staticClass: "productsGrouped__title",
                        domProps: { innerHTML: _vm._s(group.data.name) },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("product-list", {
                    attrs: {
                      loading: _vm.loading,
                      products: group.products,
                      "parent-class": ["categoryProducts"],
                      "list-class": ["categoryProductList"],
                      "card-previews": true,
                      minimal: false,
                      "card-class": [],
                      slider: true,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }