var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "productImage",
      class: [_vm.size === "large" ? "largeImages" : ""],
    },
    [
      _vm.useSlideshow
        ? _c(
            "div",
            { staticClass: "mainImageSlider" },
            [
              _c(
                "Splide",
                {
                  ref: "mainSlider",
                  attrs: { options: _vm.primarySliderOptions },
                },
                _vm._l(_vm.allImages, function (image, index) {
                  return _c("SplideSlide", { key: "img" + image.id }, [
                    _c(
                      "div",
                      { staticClass: "mainImage" },
                      [
                        _vm.allowZoom && image.zoom
                          ? _c(
                              "image-zoom",
                              {
                                attrs: {
                                  regular: image.sizes[_vm.size].displayImage,
                                  zoom: image.sizes["zoom"].displayImage,
                                  lazyload: _vm.lazyLoad,
                                  "zoom-amount": 2,
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: image.sizes[_vm.size].displayImage,
                                    alt: _vm.altImage(image.alt),
                                    width: image.sizes[_vm.size].width,
                                    height: image.sizes[_vm.size].height,
                                    loading: _vm.loading,
                                  },
                                }),
                              ]
                            )
                          : _c("img", {
                              attrs: {
                                src: image.sizes[_vm.size].displayImage,
                                alt: _vm.altImage(image.alt),
                                width: image.sizes[_vm.size].width,
                                height: image.sizes[_vm.size].height,
                                loading: _vm.loading,
                              },
                            }),
                      ],
                      1
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "mainImage" },
            [
              _vm.allowZoom && _vm.mainImage.zoom
                ? _c(
                    "image-zoom",
                    {
                      attrs: {
                        regular: _vm.mainImage.sizes[_vm.size].displayImage,
                        zoom: _vm.mainImage.sizes["zoom"].displayImage,
                        lazyload: _vm.lazyLoad,
                        "zoom-amount": 2,
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.mainImage.sizes[_vm.size].displayImage,
                          alt: _vm.altImage(_vm.mainImage.alt),
                          width: _vm.mainImage.sizes[_vm.size].width,
                          height: _vm.mainImage.sizes[_vm.size].height,
                          loading: _vm.loading,
                        },
                      }),
                    ]
                  )
                : _c("img", {
                    attrs: {
                      src: _vm.mainImage.sizes[_vm.size].displayImage,
                      alt: _vm.altImage(_vm.mainImage.alt),
                      width: _vm.mainImage.sizes[_vm.size].width,
                      height: _vm.mainImage.sizes[_vm.size].height,
                      loading: _vm.loading,
                    },
                  }),
            ],
            1
          ),
      _vm._v(" "),
      _vm.useThumbnails
        ? _c(
            "div",
            { staticClass: "thumbnailsWrap" },
            [
              _c(
                "Splide",
                {
                  ref: "thumbSlider",
                  attrs: { options: _vm.thumbnailSliderOptions },
                },
                _vm._l(_vm.allImages, function (image) {
                  return image.name
                    ? _c("SplideSlide", { key: "img" + image.id }, [
                        _c("div", { staticClass: "imageWrap" }, [
                          _c("img", {
                            attrs: {
                              src: image.sizes.thumbs.path + image.name,
                              alt: _vm.altImage(image.alt),
                              width: "100",
                              height: "100",
                              loading: _vm.loading,
                            },
                          }),
                        ]),
                      ])
                    : _vm._e()
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDisclaimer
        ? _c("div", { staticClass: "disclaimer" }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.disclaimerText) } }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }