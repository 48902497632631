<template>

    <div class="travelDestinationsWrap">


        <div class="travelDestinations" v-if="destinationList.length">

            <ul class="destinationList">

                <template v-for="(dest, index) in destinationList">

                    <li class="destinationRow" :key="'travel_destination_' + index">

                        <span v-html="getCountryName(dest)" class="destinationText"></span>

                        <span
                                class="removeIcon"
                                role="button"
                                title="Remove destination"
                                @click.prevent="removeDestination(index)"
                        >
                            <font-awesome-icon :icon="['far', 'xmark']" />
                        </span>

                        <input
                                type="hidden"
                                :name="question.name + '[]'"
                                :value="dest"
                        />

                    </li> <!-- .userRow -->

                </template>

            </ul>

        </div> <!-- .currentTravelUsers -->
        
        <div class="textRow">
            
            
            <select class="no-chosen" v-model="destination">
                
                <option value="">Select destination</option>
                
                <template v-for="(option, i) in countries">
                    
                    <option 
                            :value="option.value" 
                            v-html="option.title" 
                            :key="'travel_destination_option_' + i"
                    ></option>
                    
                </template>
                
            </select>
            
        </div> <!-- .row -->

        <div class="buttonWrap" v-if="this.destinations.length < 14">
            <button 
                    type="button" 
                    class="button buttonText addButton" 
                    title="Add user"
                    @click.prevent="addDestination()"
            >
                <span class="svgIcon">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="4" width="10" height="2" rx="1" fill="currentColor"/>
                        <rect x="6" width="10" height="2" rx="1" transform="rotate(90 6 0)" fill="currentColor"/>
                    </svg>
                </span>
                <span class="buttonText">
                    add destination
                </span>
            </button>
        </div> <!-- .travelUserButtonWrap -->
        
        
    </div> <!-- .travelUsers -->
   
    
</template>

<script>

import _ from 'lodash';

export default {
    name: "travelDestinations",
    props: {
        question: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        placeholder: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        allowInputEvent: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
    },
    data() {
        return {
            destination: '',
            destinations: [],
            destinationError: false,
            countries: []
        }
    },
    computed: {
        destinationList() {
            return this.destinations;
        },
    },
    created() {
        this.countries = this.buildCountryArray();

        this.destinations = this.question.answer;

        if(this.destinations.length) {
            this.updateQuestion(this.question);
        }
    },
    methods: {

        buildCountryArray() {
            let countryList = [];

            if(!_.isUndefined(this.question.options)) {

                if(Object.keys(this.question.options).length) {

                    const opts = this.question.options;

                    for(const key in opts) {
                        let obj = {
                            title: opts[key],
                            value: key
                        };
                        countryList.push(obj);
                    }

                }
            }

            return countryList;
        },
        
        getCountryName(key) {
            
            let countries = this.countries.filter(country => country.value == key);
            
            if(countries.length) {
                return countries[0].title;
            }
            
            return false;
            
        },
        
        addDestination() {

            this.question.can_proceed = false;
            this.destinationError = true;
            
            if(this.destination.length) {
                let destination = this.destination.trim();
                
                if(this.destinationIsValid(destination)) {
                    this.destinations.push(destination);
                    this.destination = '';
                    this.destinationError = false
                    this.question.can_proceed = true;
                }
            }
            this.updateQuestion(this.question);
            
        },
        
        removeDestination(index) {
            this.destinations = this.destinations.filter((destination, i) => i != index );
            this.updateQuestion(this.question);
        },
        
        updateQuestion: _.debounce(function(question, inputEvent) {

            question = this.validate(question);
            this.$emit('update-question', question);
            
        }, 200),
        
        validate(question) {

            let canProceed = true;
            
            if (this.destinations.length && !this.destinationError) {
                canProceed = true;
            }else{
                if(question.is_required) {
                    canProceed = false;
                }
            }
            
            question.can_proceed = canProceed;
            
            return question;
            
        },
        
        destinationIsValid(destination) {

            let valid = false;
            
            if(!_.isUndefined(destination)) {
                
                if(destination.length) {
                    valid = true;
                }
                
            }
            
            return true;
            
        },

        externallyValidate() {
            let question = this.question;

            if(this.destination.length) {
                this.addDestination();
            }
            
            question = this.validate(question);
            this.$emit('update-question', question);
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .travelDestinationsWrap {
        
        .travelDestinations {
            padding: 0 0 15px;
        }

        .destinationList {

            margin: 0;
            padding: 0;

            &:before, &:after {
                display: none;
            }

            & > li {
                list-style: none;
                display: flex;
                padding: 7px 0 7px 20px;
                border: 1.5px solid rgba(102, 102, 102, 0.5);;
                border-radius: 5px;
                margin: 0 0 15px;
                position: relative;

                &:last-child {
                    margin: 0;
                }
            }

            .destinationText {
                flex: 1 1 100%;
                padding: 0 56px 0 0;
            }

            .removeIcon {
                flex: 0 0 48px;
                width: 48px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: 0;
                color: @text_color;
                transition: color 200ms linear;
                cursor: pointer;
                display: block;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin: 0;
                }

                &:hover, &:focus, &:active {
                    color: @alert_color;
                    transition: color 200ms linear;
                }
            }
        }

        .textRow {
            input {
                @media @mobile {
                    text-align: center !important;
                }
            }
        }

        .buttonWrap {
            padding: 15px 0 0;
        }
        
        .addButton {
            letter-spacing: 0.1em;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: flex-start;
            overflow: visible;
            width: 100%;
            text-align: left;

            @media @mobile {
                display: flex;
                justify-content: center;
                text-align: center;
            }
            
            &:before, &:after {
                display: none;
            }
            
            
            .svgIcon {
                flex: 0 0 12px;
                padding: 0;
                position: relative;
                top: -1px;
                
                svg {
                    position: relative;
                    width: 10px;
                    height: 10px;
                    margin-top: 0;
                }
            }
            
            .buttonText {
                flex: 1 1 100%;
                padding-left: 8px;
                text-align: left;

                @media @mobile {
                    flex: 0 0 auto;
                }
            }
        }
        
    }
    

</style>
