var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "productListWrap",
      class: [_vm.loading ? "isLoading" : "", ..._vm.parentClass],
      attrs: { id: _vm.parentId },
    },
    [
      _vm.slider
        ? [
            _c(
              "Splide",
              {
                ref: "productSlider",
                class: [_vm.products.length < 4 ? "hideArrows" : ""],
                attrs: { options: _vm.sliderOptions },
              },
              _vm._l(_vm.products, function (product) {
                return _c(
                  "SplideSlide",
                  { key: "img" + product.id },
                  [
                    _c("product-card", {
                      class: [..._vm.cardClass],
                      attrs: {
                        product: product,
                        "in-stock": product.in_stock,
                        "from-price": product.from_price,
                        "product-url": product.url,
                        "product-image": product.image,
                        "basket-button-data": product.basket_button_data,
                        category: product.main_category,
                        "preview-only": _vm.cardPreviews,
                        slideshow: false,
                        minimal: _vm.minimal,
                        "use-trustpilot": true,
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ]
        : [
            _vm.hasProducts(_vm.products)
              ? _c(
                  "ul",
                  { staticClass: "productList", class: [..._vm.listClass] },
                  [
                    _vm._l(_vm.products, function (product) {
                      return [
                        _c(
                          "li",
                          [
                            _c("product-card", {
                              class: [..._vm.cardClass],
                              attrs: {
                                product: product,
                                "in-stock": product.in_stock,
                                "from-price": product.from_price,
                                "product-url": product.url,
                                "product-image": product.image,
                                "basket-button-data":
                                  product.basket_button_data,
                                category: product.main_category,
                                "preview-only": _vm.cardPreviews,
                                slideshow: false,
                                minimal: _vm.minimal,
                                "use-trustpilot": true,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }