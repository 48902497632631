<template>

    <div class="datepickerWrap">

        <datepicker
                :name="questionName"
                :id="'id_' + question.id"
                v-model="question.answer"
                :data-question-id="question.id"
                @input="updateQuestion(question)"
                autocomplete="off"
        ></datepicker>


    </div><!-- .datepickerWrap -->
    
</template>

<script>

import _ from 'lodash';
import { props, computed, methods } from "../mixins/fields";

export default {
    name: "dateField",
    mixins: [props, computed, methods],
    methods: {

        updateQuestion: _.debounce(function(question, inputEvent) {

            if(typeof inputEvent == 'undefined' || this.allowInputEvent) {
                question = this.validate(question);
                this.$emit('update-question', question);
            }

        }, 200),

        validate(question) {

            let canProceed = true;
            question.custom_error = '';

            if (question.answer.length) {
                
                let answer = question.answer.trim();
                let re = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
                let isValid = re.test(answer);
                
                if(isValid && question.is_required) {

                    const birthDate = new Date(answer);

                    // Get today's date
                    const today = new Date();

                    // Calculate the difference in years
                    let age = today.getFullYear() - birthDate.getFullYear();

                    // Check if the current date is before the birth date in the current year.
                    // If so, subtract one year from the age
                    if (today.getMonth() < birthDate.getMonth() ||
                            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
                        age--;
                    }

                    console.log(age);
                    // Return true if the age is 18 or older, false otherwise
                    if(age < 18) {
                        question.custom_error = 'To continue, you must be at least 18 years old';
                        isValid = false;
                    }
                    

                }
                

                console.log('e', question.custom_error);
                canProceed = isValid;
                
            }else{
                if(question.is_required) {
                    canProceed = false;
                }
            }

            question.can_proceed = canProceed;

            return question;

        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .datepickerWrap {

        input[type="text"] {
            border-radius: 59px !important;
        }
        
    }

</style>
