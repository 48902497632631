var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stockWrap" }, [
    !_vm.hasStock
      ? _c("span", { staticClass: "bubbleText" }, [_vm._v("Out of Stock")])
      : _c("span", { staticClass: "bubbleText" }, [_vm._v("In Stock")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }