var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notifyGP" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.question.answer,
            expression: "question.answer",
          },
        ],
        attrs: { type: "hidden", name: _vm.questionName },
        domProps: { value: _vm.question.answer },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.question, "answer", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("gp-surgery-search", {
        attrs: {
          "search-url": "/shop/ajax/doctor_surgery_search.php",
          "search-placeholder": "Search doctor surgery name or address",
          "user-surgery": _vm.surgery,
          "instance-id": _vm.question.id,
          "multi-user-id": _vm.multiuserId,
        },
        on: { "address-updated": _vm.updateAnswer },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }